// Import dependencies
import React from "react";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import Logo from "../assets/icons/logo_white_nav.svg";
import { PageContainer } from "../styled-components/UILibrary"
import InputField from "../components/form/input/inputField";
import { Link, navigate } from "gatsby";


import CaseStudy1 from "../assets/images/case-study1.png";
import CaseStudy2 from "../assets/images/case-study2.png";
import CaseStudy3 from "../assets/images/case-study3.png";

import ClaimBrand1 from "../assets/images/claim_brand1.png";
import ClaimBrand2 from "../assets/images/claim_brand2.png";
import ClaimBrand3 from "../assets/images/claim_brand3.png";

import { STRIPE_CONSTANT, DEPLOY_TYPE } from "../constants/stripe"

import { Form } from "antd";
import { getFirebase } from "../api/firebase"
import {InlineShareButtons} from 'sharethis-reactjs';

// Import styless
import "./become-driver.css";
import "./case-study.css";
import "./index.css";

/*
    Index page component
*/
class ClaimBrand extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: null
      };
      this.submitForm = this.submitForm.bind(this);
    }

    submitForm = () => {
      if (this.state.email != null) {
        const app = import("firebase/app");
        const firestore = import("firebase/firestore");
        const fuctions = import("firebase/functions");
  
        let date = new Date();

        const dd = date.getDate();
        const mm = date.getMonth();
        const arrayMonths = [ "January", "February", "March", "April", "May", "June",
         "July", "August", "September", "October", "November", "December"];
        const yyyy = date.getFullYear();
        let today =  dd + ' ' + arrayMonths[mm] + ' ' + yyyy;
    
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let now = hours + ':' + minutes + ' ' + ampm;

        Promise.all([app, fuctions]).then(([firebase]) => {
          var claimBrand = getFirebase(firebase)
            .functions()
            .httpsCallable("claimBrand");
            claimBrand({
              email: this.state.email,
              date: today,
              time: now
            });
        });

        navigate("/application-sent");

      }
    }

    render() {
      const { form } = this.props;
      return (
        <Layout noWhatsapp noHeader>


            <SEO
                title="Claim this brand"
                description="On-demand buy-back and recycling for used electronics, books, and clothes."
            />
            <PageContainer className="form_page">
              <div className="form_page_container claim_brand_container">
                <Link to="/" className="form_page_container_logo">
                  <Logo/>
                </Link>
                <div className="form_page_flex">
                  <div className="form_main_container">
                    <h1>Claim this brand</h1>
                    <p>Your email extension needs to match the name of this brand, e.g. bob@ikea.com</p>

                    <InputField
                      placeholder="Enter your company email"
                      type="email"
                      form={form}
                      id="email"
                      inputPlaceholder="example@brand-name.com"
                      onChange={e => this.setState({email: e.target.value})}
                    /> 
                  <div className="form_main_submit_button" onClick={() => this.submitForm()}>Request access</div>     
                  </div>
                  <div className="form_main_side">
                    <div className="form_main_side_item">
                      <img src={CaseStudy1} />
                      <div className="form_main_side_item_info">
                        <h1>Recycling as a service</h1>
                        <p>Get a verified badge next to your brand’s name</p>
                      </div>
                    </div>
                    <div className="form_main_side_item">
                      <img src={CaseStudy2} />
                      <div className="form_main_side_item_info">
                        <h1>Recycling as a service</h1>
                        <p>Let Reinin buy-back & recycle your products for you</p>
                      </div>
                    </div>
                    <div className="form_main_side_item">
                      <img src={CaseStudy3} />
                      <div className="form_main_side_item_info">
                        <h1>Monitor your impact</h1>
                        <p>Real-time buy-back & recycling metrics</p>
                      </div>
                    </div>


                  </div>
                </div>

              <div className="case_study_container">
                <h1>Why should brands partner with Reinin?</h1>
                <div className="case_study_item claim_brand_item">
                  <div className="claim_brand_img">
                    <img src={ClaimBrand1} style={{height: "auto", width: 470, left: -30}} />
                  </div>
                  <div className="case_study_item_info">
                    <h2>Turnkey</h2>
                    <p>Get a <b>free</b> managed buy-back page, and leverage our on-demand collection capabilities, buy-back settlement, recycling fulfilment, and brand-specific analytics</p>
                  </div>
                </div>
                <div className="case_study_item claim_brand_item">
                <div className="claim_brand_img">
                  <img src={ClaimBrand2} style={{height: "auto", width: 415, left: -10, top: -50}} />
                </div>
                  <div className="case_study_item_info">
                    <h2>Data</h2>
                    <p>The Reinin brand dashboard summarizes recycling rates across all your brand’s product lines, with data fit for ESG reports and Singapore EPR monitoring</p>
                  </div>
                </div>
                <div className="case_study_item claim_brand_item">
                <div className="claim_brand_img">
                  <img src={ClaimBrand3} style={{height: "auto", width: 480, left: -34, top: -32}} />
                </div>
                  <div className="case_study_item_info">
                    <h2>Challenges</h2>
                    <p>Create both general and product-specific recycling challenges for your customers, and reward them with either Reinin Coins, cash, online or in-store discounts when they upgrade to new products</p>
                  </div>
                </div>
              </div>

              <div className="index_share">
                <span>Share this page with your friends</span>

                <div className="index_share_list">

                  <div className="index_share_item">
                    <InlineShareButtons
                      config={{
                        color: 'social',
                        enabled: true,
                        networks: ['messenger'],
                        url: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"],
                      }}
                    />
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_557_9824)">
                    <path d="M0 23.1479C0 30.4312 3.63333 36.9292 9.31458 41.1729V50L17.8271 45.3292C20.0979 45.9562 22.5042 46.2979 24.9979 46.2979C38.8042 46.2979 49.9979 35.9354 49.9979 23.15C50 10.3646 38.8062 0 25 0C11.1937 0 0 10.3625 0 23.1479H0ZM22.3625 16.6646L28.8833 23.4542L41.1479 16.6646L27.4812 31.1687L21.1167 24.3812L8.69375 31.1708L22.3625 16.6646Z" fill="#2196F3"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_557_9824">
                    <rect width="50" height="50" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                  </div>


                  <div className="index_share_item">
                    <InlineShareButtons
                        config={{
                          color: 'social',
                          enabled: true,
                          networks: ['whatsapp'],
                          url: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"],
                        }}
                      />
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_557_9826)">
                    <path d="M42.75 7.18542C38 2.59375 31.75 0 25.0938 0C5.98958 0 -6.00625 20.6979 3.53333 37.1625L0 50L13.1979 46.5583C18.9479 49.6646 23.6583 49.3896 25.1062 49.5729C47.2583 49.5729 58.2854 22.7729 42.7187 7.26667L42.75 7.18542Z" fill="#ECEFF1"/>
                    <path d="M25.1396 45.3143L25.1271 45.3122H25.0938C18.4646 45.3122 14.2292 42.1726 13.8125 41.9914L6 44.0226L8.09375 36.4289L7.59583 35.6476C5.53333 32.3643 4.4375 28.583 4.4375 24.6893C4.4375 6.37054 26.8229 -2.78987 39.7771 10.158C52.7 22.9705 43.6271 45.3143 25.1396 45.3143Z" fill="#4CAF50"/>
                    <path d="M36.473 29.8065L36.4542 29.9628C35.8271 29.6503 32.773 28.1565 32.2042 27.9503C30.9271 27.4774 31.2875 27.8753 28.8355 30.6836C28.4709 31.0899 28.1084 31.1211 27.4896 30.8399C26.8646 30.5274 24.8584 29.8711 22.4834 27.7461C20.6334 26.0899 19.3917 24.0586 19.025 23.4336C18.4146 22.3794 19.6917 22.2294 20.8542 20.0294C21.0625 19.5919 20.9563 19.2482 20.8021 18.9378C20.6459 18.6253 19.4021 15.5628 18.8813 14.3419C18.3813 13.1253 17.8667 13.2794 17.4813 13.2794C16.2813 13.1753 15.4042 13.1919 14.6313 13.9961C11.2688 17.6919 12.1167 21.5044 14.9938 25.5586C20.648 32.9586 23.6605 34.3211 29.1688 36.2128C30.6563 36.6857 32.0125 36.619 33.0855 36.4649C34.2813 36.2753 36.7667 34.9628 37.2855 33.494C37.8167 32.0253 37.8167 30.8065 37.6605 30.5253C37.5063 30.244 37.098 30.0878 36.473 29.8065Z" fill="#FAFAFA"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_557_9826">
                    <rect width="50" height="50" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                  </div>

                  <div className="index_share_item">
                    <InlineShareButtons
                        config={{
                          color: 'social',
                          enabled: true,
                          networks: ['wechat'],
                          url: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"],
                        }}
                      />
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_557_9830)">
                    <path d="M42.602 19.9751C29.2937 13.2085 14.1687 25.6314 21.0854 37.0293C24.2958 42.3835 31.7083 45.7647 40.4791 43.2272C42.2333 43.9085 43.7958 45.0126 45.4958 45.8335C45.0562 44.3543 44.5874 42.8918 44.0874 41.4355C53.1499 34.9793 51.1666 24.3251 42.602 19.9751ZM31.9791 27.6585C31.5437 29.023 29.5708 29.4314 28.6145 28.3939C27.5604 27.4335 27.9708 25.4272 29.3541 24.998C30.8833 24.3522 32.6499 26.1251 31.9791 27.6585ZM41.9374 27.8543H41.9666C41.4312 29.0772 39.5895 29.3647 38.6854 28.4168C38.2499 28.0168 38.1229 27.4147 37.9687 26.8897C38.1854 25.9314 38.8437 24.9376 39.9062 24.8918C41.3749 24.6876 42.6895 26.5085 41.9374 27.8543Z" fill="#4CAF50"/>
                    <path d="M36.2791 17.1834C35.3708 12.7167 32.2458 8.94379 28.3125 6.76671H28.3687V6.76463C14.4104 -1.12287 -2.96877 9.87921 0.389563 22.8542C1.29165 26.7042 3.95415 29.9313 7.1854 32.0855C6.5604 33.8625 5.96665 35.6396 5.40415 37.4292C7.4354 36.3625 9.46665 35.248 11.5 34.1646C13.9375 34.95 16.5312 35.3334 19.125 35.248C15.9208 26.0563 24.1396 16.3188 36.2791 17.1834ZM23.6187 11.848C25.2541 11.1896 27.1875 12.5667 27.0583 14.3271L27.0625 14.3521C27.0791 16.3625 24.4062 17.6167 22.9062 16.2542C21.35 15.1334 21.7916 12.4105 23.6187 11.848ZM14.4416 14.8625C14.0833 16.6084 11.7521 17.4521 10.3875 16.2792C8.80623 15.1605 9.24998 12.3875 11.1062 11.823C12.925 11.1188 14.9916 12.973 14.4416 14.8625Z" fill="#4CAF50"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_557_9830">
                    <rect width="50" height="50" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                  </div>

                  <div className="index_share_item">
                    <InlineShareButtons
                        config={{
                          color: 'social',
                          enabled: true,
                          networks: ['telegram'],
                          url: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"],
                        }}
                      />
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#039BE5"/>
                    <path d="M11.4396 24.4583L35.5438 15.1645C36.6626 14.7604 37.6396 15.4374 37.2771 17.1291L37.2792 17.127L33.1751 36.4624C32.8709 37.8333 32.0563 38.1666 30.9167 37.5208L24.6667 32.9145L21.6521 35.8187C21.3188 36.152 21.0376 36.4333 20.3917 36.4333L20.8355 30.0729L32.4188 19.6083C32.923 19.1645 32.3063 18.9145 31.6417 19.3562L17.3271 28.3687L11.1563 26.4437C9.81673 26.0187 9.78756 25.1041 11.4396 24.4583V24.4583Z" fill="white"/>
                    </svg>
                  </div>


                </div>
              </div>

              </div> 
            </PageContainer>


        </Layout>
    );
  
  }
}



export default Form.create({name: "claim_brand-form"})(ClaimBrand);
